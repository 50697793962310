<template>
    <div>
        <ul class="p-3 pt-0 d-flex text-center fw-400 gap-2 cate_btn_list">
            <li class="rounded lh-1 w-50" 
            :class="{'bg-yellow-1 fw-bold':item.idx==idx , 'bg-yellow-4 text-gray-500' :item.idx != idx, 'hot':item.idx==idx&&item.idx==0}" @click="$router.push(`/deal/list/${item.link}`)" v-for="(item,index) in list" :key="index"><span class="zindex-1">{{item.name}}</span></li>
        </ul>
        <div  class="mx-3 text-white flex-between-center mb-3">
            <ul class="flex-start-center gap-3 fs-px-13">
                <li>
                    <span class="fw-bold text-yellow-1 me-1">·</span>
                    <span>{{CheckCoin()}}</span>
                </li>
                <li>
                    <span class="fw-bold text-yellow-1 me-1">·</span>
                    <span>{{CheckSort()}}</span>
                </li>
            </ul>
            <div @click="sort_show = true">
                <img src="@/assets/img/layout_icon/sort.svg" alt="sort" width="24">
            </div>
        </div>
        
        <div class="basic_pp bottom_up text-dark" v-show="sort_show">
            <div class="dimm bg-body-i bg-opacity-50" @click="Close()"></div>
            <div class="content bg-white">
                <h6 class="text-center fw-bold pt-3 pb-4">FILTER</h6>

                <!-- closed deal 이 아닐때 -->
                <div v-show="idx != 2">
                    <p class="mb-2 fs-px-14">Product's Token Type</p>
                    <div>
                        <ul class="sort_btn_list">
                            <li class="w-25"
                                :class="{'on':select_sort_reward_idx == -1}"
                                @click="ClickReward('',-1)">
                                All
                            </li>
                            <li v-for="(item,index) in sort_coin_list" :key="index"
                                class="w-25"
                                :class="{'on':select_sort_reward_idx == item.idx}"
                                @click="ClickReward(item.code,index)">
                                {{item.name}}
                            </li>
                        </ul>
                    </div>
                    <p class="mb-2 mt-3 fs-px-14">Ticket's Token Type</p>
                    <div>
                        <ul class="sort_btn_list">
                            <li class="w-25"
                                :class="{'on':select_sort_buy_idx == -1}"
                                @click="ClickBuy('',-1)">
                                All
                            </li>
                            <li v-for="(item,index) in sort_coin_list" :key="index"
                                class="w-25"
                                :class="{'on':select_sort_buy_idx == item.idx}"
                                @click="ClickBuy(item.code,index)">
                                {{item.name}}
                            </li>
                        </ul>
                    </div>
                    <p class="mb-2 mt-3 fs-px-14">Filter</p>
                    <div>
                        <ul class="sort_btn_list flex-wrap">
                            <li v-for="(item,index) in sort_list" :key="index"
                                class="w-50"
                                :class="{'on':select_sort_idx == item.idx}"
                                @click="ClickFilter(item.code,index)">
                                {{item.name}}
                            </li>
                        </ul>
                    </div>
                </div>

                <!-- close deal 일때 -->
                <div v-show="idx == 2">
                    <div>
                        <ul class="sort_btn_list">
                            <li v-for="(item,index) in closed_sort_list" :key="index"
                                :class="{'on':select_closed_sort_idx == item.idx}"
                                @click="ClickCloseFilter(item.code,index)">
                                {{item.name}}
                            </li>
                        </ul>
                    </div>
                </div>

            
                <div class="d-flex w-100 mt-4 pt-2">
                    <button class="py-3 fs-px-12 btn btn-transparent w-50" @click="Close()">CANCEL</button>
                    <button class="py-2 fs-px-12 fw-bold btn bg-yellow-1 w-50" @click="Sort()">CONFIRM</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        idx: {
            type: Number,
            default: -1,
        },
    },
    data(){
        return{
            sort_show: false,
            list: [
                {
                    idx: 0,
                    link: 'hot',
                    name: 'HOT DEALS',
                },
                {
                    idx: 1,
                    link: 'open',
                    name: 'OPEN DEALS',
                },
                {
                    idx: 2,
                    link: 'closed',
                    name: 'CLOSED DEALS',
                },
            ],

            sort_coin_list: [
                {
                    idx: 0,
                    name: "OCB",
                    code: "OCB",
                },
                {
                    idx: 1,
                    name: "BTC",
                    code: "BTC",
                },
                
                {
                    idx: 2,
                    name: "ETH",
                    code: "ETH",
                },
                {
                    idx: 3,
                    name: "TRX",
                    code: "TRX",
                },
                {
                    idx: 4,
                    name: "USDT",
                    code: "USDT",
                },
                {
                    idx: 5,
                    name: "BNB",
                    code: "BNB",
                },
            ],
            sort_list: [
                {
                    idx: 0,
                    name: "Most Recent",
                    code : 'N'
                },
                {
                    idx: 1,
                    name: "Almost sold out",
                    code : 'A'
                },
                {
                    idx: 2,
                    name: "Deal Value",
                    code : 'P'
                },
                {
                    idx: 3,
                    name: "Lowest Ticket Price",
                    code : 'T'
                },
            ],
            
            closed_sort_list: [
                {
                    idx: 0,
                    name: "Most Recent",
                    code : 'N'
                },
                {
                    idx: 1,
                    name: "Seller's Highest Profit",
                    code : 'S'
                },
                {
                    idx: 2,
                    name: "Buyer's Highest Profit",
                    code : 'B'
                },
            ],
            select_sort_reward_idx: -1,
            selected_sort_reward_idx : -1,
            reward : '',
            selected_reward : '',
            select_sort_buy_idx: -1,
            selected_sort_buy_idx: -1,
            buy : '',
            selected_buy : '',
            select_sort_idx: 0,
            selected_sort_idx: 0,
            sort : 'N',
            selected_sort : 'N',
            select_closed_sort_idx: 0,
            selected_closed_sort_idx: 0,
            close_sort : 'N',
            selected_close_sort : 'N',

        }
    },
    mounted(){
        const code = this.$route.params.code;
        if(code =='new'){
            this.sort = 'N'
        }else if(code =='almost'){
            this.sort = 'A'
        }
        
        for (const el of this.sort_list) {
            const code = el.code;
            if(this.sort ==code){
                this.select_sort_idx = el.idx;
                this.selected_sort_idx = el.idx;
            }

        }
        this.CheckSort();
    },
    methods:{
        CheckCoin(){
            let text ='';

            if(this.selected_reward ==''){
                text = 'ALL';
            }else{
                for (const el of this.sort_coin_list) {
                    if(el.code ==this.selected_reward){
                        text = el.name
                    }
                }
            }

            if(this.selected_buy ==''){
                text += ' / ALL';
            }else{
                for (const el of this.sort_coin_list) {
                    if(el.code ==this.selected_buy){
                        text = text + ' / '+el.name
                    }
                }
            }

            return text
        },
        CheckSort(){
            if(this.idx ==2){
                for (const el of this.closed_sort_list) {
                    if(el.idx ==this.selected_closed_sort_idx){
                        return el.name
                    }                    
                }
            }else{
                for (const el of this.sort_list) {
                    if(el.idx ==this.selected_sort_idx){
                        return el.name
                    }                    
                }
            }
        },
        ClickReward(code,index){
            this.reward = code;
            this.select_sort_reward_idx = index;
        },
        ClickBuy(code,index){
            this.buy = code;
            this.select_sort_buy_idx = index;
        },
        ClickFilter(code,index){
            this.sort = code;
            this.select_sort_idx = index;
        },
        ClickCloseFilter(code,index){
            this.close_sort = code;
            this.select_closed_sort_idx = index;
        },
        Close(){
            this.sort_show = false;
            this.select_sort_reward_idx = this.selected_sort_reward_idx;
            this.select_sort_buy_idx = this.selected_sort_buy_idx;
            this.select_sort_idx = this.selected_sort_idx;
            this.select_closed_sort_idx = this.selected_closed_sort_idx;
            this.reward = this.selected_reward;
            this.buy = this.selected_buy;
            this.sort = this.selected_sort;
            this.close_sort = this.selected_close_sort;

            this.CallFunction()
        },
        Sort(){
            this.sort_show = false;
            this.selected_sort_reward_idx = this.select_sort_reward_idx
            this.selected_sort_buy_idx = this.select_sort_buy_idx;
            this.selected_sort_idx = this.select_sort_idx;
            this.selected_closed_sort_idx = this.select_closed_sort_idx;

            this.selected_reward = this.reward;
            this.selected_buy = this.buy;
            this.selected_sort = this.sort;
            this.selected_close_sort = this.close_sort;
            
            this.CallFunction()
        },
        CallFunction(){
            const reward = this.reward;
            const buy = this.buy;
            const sort = this.sort;
            const close_sort = this.close_sort;

            const info = {reward,buy,sort,close_sort}
            this.$emit('ChangeSort', info);
        }
    }
}
</script>

<style lang="scss" scoped>
    .cate_btn_list li{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50px;
        padding: 0 5px;
        font-size: 14px;
    }

    .hot{
        color: #fff;
        overflow: hidden;
        position: relative;
    }
    .hot::before{
        content: "";
        position: absolute;
        width: 120%;
        height: 0;
        padding-top: 120%;
        background: linear-gradient(0deg, #FFB800, #FF2E00);
        transform: rotate(0deg);
        animation: rotate_bg 2.5s linear infinite;
    }
    @keyframes rotate_bg {
        0% {
            transform: rotate(0deg) scale(1);
        }
        100% {
            transform: rotate(360deg) scale(1);
        }
    }

    .sort_btn_list{
        display: flex;
        justify-content: start;

        li{
            margin: 0;
            font-size: 13px;
            margin-right: -1px;
            margin-top: -1px;
            flex-grow: 1;
            text-align: center;
            padding: 8px 5px;
            border: 1px solid #AAAAAA;
            box-sizing: border-box;;
            background-color: #EDEDED;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 40px;
        }

        li.on{
            font-weight: bold;
            border: 1px solid #151515;
            background-color: #fff;
            z-index: 1;
        }
    }

</style>