<template>
    <div class="pb-0">
        <SubHeader :title="'Upload Deal'" />
        <div class="content_wrap">
            <div>
                <p class="text-white mb-2">1) DEAL Title</p>
                <div class="rounded-1 h-px-48 bg-white flex-between-center">
                    <input type="text" class="form-control border-0 h-100" placeholder="max 20 characters." v-model="title" @keyup="CheckTitle()" required>
                </div>
                <p v-show="title_error" class="text-danger pt-3">Exceeds character limit.</p>
            </div>
            <hr class="border-yellow-1">
            <div>
                <p class="text-white mb-2">2) Seller's Comment</p>
                <div class="rounded-1 bg-white flex-between-center">
                    <textarea type="text" class="form-control border-0 h-px-100" placeholder="max 100 characters." maxlength="100" v-model="comment" @input="comment.length>=100?comment_error=true:comment_error=false"  required></textarea>
                </div>
                <p v-show="comment_error" class="text-danger pt-3">Exceeds character limit.</p>
            </div>
            <hr class="border-yellow-1">
            <div>
                <p class="text-white mb-2">3) Deal's Product Information</p>
                <p class="text-white mb-2 small flex-between-center">
                    3-1) Select token type <i class="fas" :class="{'fa-question-circle':!token_sell_desc,'fa-times-circle':token_sell_desc}" @click="token_sell_desc=!token_sell_desc"></i>
                </p>
                <p v-show="token_sell_desc" class="small text-yellow-1 pb-2">What Do I Want To Sell ? </p>
                <ul class="prize_token flex-start-center text-center gap-2 flex-wrap my-3">
                    <li class="border rounded-pill flex-between-center py-1 px-2 opacity-75" :class="{'select opacity-100 border-yellow-1 bg-yellow-1':prizeTokenSelect==index, 'border-burgundy-4':prizeTokenSelect!=index}" v-for="(item,index) in prize_token" :key="index" @click="ClickTradingCoin(item,index)">
                        <img :src="require(`@/assets/img/icon/color/${item.token_name}.svg`)" height="35" :alt="item.token_name" class="img-fluid w-100 me-2 border-yellow-1">
                        <span class="uppercase px-2" :class="{'text-burgundy-5':prizeTokenSelect!==index, 'text-dark fw-bold':prizeTokenSelect===index}">{{item.token_name}}</span>
                    </li>
                </ul>
                
                <p class="text-white mb-2 small flex-between-center">
                    3-2) Enter token quantity
                    <i class="fas" :class="{'fa-question-circle':!token_quantity_desc,'fa-times-circle':token_quantity_desc}" @click="token_quantity_desc=!token_quantity_desc"></i>
                </p>
                <p v-show="token_quantity_desc" class="small text-yellow-1 pb-2">Rate retrieved from Binance at {{last_time}}</p>
                <div class="rounded-1 h-px-48 bg-white flex-start-center px-2 text-truncate">
                    
                    <input id="prizeQuantity" type="number" class="form-control border-0 h-100 px-0 fw-bold" placeholder="0" v-model="trading_price" @keyup="CheckTradingPrice()" autocomplete='off' />
                    <label for="prizeQuantity" class="uppercase ps-2"><b>{{ prize_token[prizeTokenSelect].token_name }}</b>
                    <small class="text-burgundy-1" v-if="trading_price !=0 || trading_price !=''">= {{trading_usdt_price}} USD</small>
                    </label>
                </div>
                <p class="text-burgundy-4 py-2">My Balance : {{CheckBalance(trading_coin,trading_my_price)}} <span class="uppercase">{{prize_token[prizeTokenSelect].token_name}}</span></p>
            </div>
            <hr class="border-yellow-1">
            <div>
                <p class="text-white mb-2 small flex-between-center">
                    4) Enter your desired profit %
                    <i class="fas" :class="{'fa-question-circle':!profit_desc,'fa-times-circle':profit_desc}" @click="profit_desc=!profit_desc"></i>
                </p>
                <p v-show="profit_desc" class="small text-yellow-1 pb-2">Entering desidered profit % can help you set ticket information</p>

                <!-- <div class="rounded-1 h-px-48 bg-white flex-between-center"> -->
                <div class="rounded-1 h-px-48 bg-white flex-start-center px-2 text-truncate">
                    <input id="profit" type="text" class="form-control border-0 h-100 px-0 " placeholder="0" v-model="profit"   @keyup="CheckProfit()"  autocomplete='off'>
                    <label for="profit" class="uppercase ps-2"><b>%</b></label>
                </div>
                <!-- </div> -->
            </div>
            <hr class="border-yellow-1">
            <div>
                <p class="text-white mb-2">5) Deal's ticket information</p>
                <p class="text-white mb-2 small">5-1) Select Token Type</p>
                
                <ul class="prize_token flex-start-center text-center gap-2 flex-wrap my-3">
                    <li class="border rounded-pill flex-between-center py-1 px-2 opacity-75" :class="{'select opacity-100 border-yellow-1 bg-yellow-1':sellTokenSelect==index, 'border-burgundy-4':sellTokenSelect!=index}" v-for="(item,index) in sell_token" :key="index" @click="ClickSellCoin(item,index)">
                        <img :src="require(`@/assets/img/icon/color/${item.token_name}.svg`)" height="35" :alt="item.token_name" class="img-fluid w-100 me-2 border-yellow-1">
                        <span class="uppercase px-2" :class="{'text-burgundy-5':sellTokenSelect!==index, 'text-dark fw-bold':sellTokenSelect===index}">{{item.token_name}}</span>
                    </li>
                </ul>
                <div class="py-2 rounded-1 bg-yellow-1 w-100 mb-3 position-relative text-center">
                    <small>Total amount (5-2 x 5-3)</small>
                    <i class="position-absolute end-0 translate-middle-x fas" :class="{'fa-question-circle':!sell_token_desc,'fa-times-circle':sell_token_desc}" @click="sell_token_desc=!sell_token_desc"></i>
                    <p v-show="sell_token_desc" class="text-danger fs-px-12 fw-bold py-2">
Binance matches the rate of return entered by the seller and the token type of 5-1.<br>Based on this, the total ticket sales amount is automatically calculated.</p>
                    <p class="fs-px-11"><span class="text-burgundy-1 fw-bold"><b class="fs-6">{{sell_amount}}</b> {{sell_coin}}</span> = {{trading_total_usdt_price}} USD</p>
                </div>
                <p class="text-white mb-1 small flex-between-center">
                    5-2) Price per 1 Ticket
                </p>
                <p class="small text-burgundy-4 pb-2">Lowest possible price is ({{ sell_coin_t_min }}) {{ sell_coin }}</p>
                <div class="rounded-1 h-px-48 bg-white flex-start-center px-2 text-truncate mb-3">
                    <input id="sellQuantity" type="text" class="form-control border-0 h-100 px-0 w-initial fw-bold" placeholder="0" v-model="sell_price"  @keyup="CheckSellPrice()"  autocomplete='off'>
                    <label for="sellQuantity" class="uppercase ps-2"><b>{{ sell_token[sellTokenSelect].token_name }}</b>
                    <small class="text-burgundy-1" v-if="sell_price !=0 || sell_price!=''">= {{sell_usdt_price}} USD</small>
                    </label>
                </div>

                
                <p class="text-white mb-1 small flex-between-center">
                    5-3) Total Ticket Quantity
                </p>
                <!-- <p class="small text-burgundy-4 pb-2">Price per ticket is too high, does not meet required minimum number of tickets.</p> -->
                <div class="rounded-1 h-px-48 bg-white flex-start-center text-truncate mb-3">
                    <input type="text" class="form-control border-0 w-100" placeholder="Quantity calculated automatically" v-model="total_ticket" readonly   @keyup="CheckSellTicket()"  autocomplete='off'>
                </div>
                
                <p class="text-white mb-1 small flex-between-center">
                    5-4) Select deal's valid time
                </p>
                <div class="flex-between-center my-2">
                    <div class="py-3 border-0 rounded-1 btn w-100 flex-center-center w-50 btn me-2" :class="{'fw-bold bg-yellow-1':deal_range===0,'bg-yellow-4':deal_range!==0}" @click="deal_range=0">
                        72HOURS
                    </div>
                    <div class="py-3 border-0 rounded-1 w-100 flex-center-center w-50 btn" :class="{'fw-bold bg-yellow-1':deal_range===1,'bg-yellow-4':deal_range!==1}" @click="deal_range=1">
                        1WEEK
                    </div>
                </div>
                <p class="small text-burgundy-4">* Valid time will start counting once deal has been succesfully uploaded<br>
* If the deal is not closed wihtin the valid time, the deal will be automatically cancelled, all ticket purchases will be refunded and no cancellation will incurr.<br>
* If a uploaded deal is cancelled by the decision of seller, {{cancel_fee}}% cancellation fee will be charged to seller.</p>
            </div>
            <hr class="border-yellow-1">
            <div>
                <div class="mb-1 small flex-between-center">
                    <p class="text-white">6) Hot Deal Service <small class="text-yellow-1"> *OPTIONAL</small></p>
                    <i class="text-white fas fa-question-circle" @click="hotdeal_desc=!hotdeal_desc"></i>
                </div>
                <div class="position-fixed w-100 min-h-vh-100 max-h-vh-100 start-0 top-0 bg-yellow-1 p-3 zindex-900" v-show="hotdeal_desc" style="overflow-y:auto;">
                    <div style="max-width:768px; margin:0 auto;">
                        <i class="fas fa-chevron-left text-burgundy-2 h3 fw-bold ps-2" @click="hotdeal_desc=!hotdeal_desc"></i>
                        <p class="text-center py-3"><b class="fw-bold">‘HOT DEAL’</b>is displayed on the main screen as follows.</p>
                        <img src="@/assets/img/uploadticket_hotdeal.png"  class="img-fluid w-100" alt="">
                    </div>
                </div>
                <div class="flex-between-center my-2">
                    <div class="py-3 border-0 rounded-1 btn w-100 flex-center-center w-50 h-px-60 btn me-2 d-flex flex-column" :class="{'fw-bold bg-yellow-1':hotdeal_range===0,'bg-yellow-4':hotdeal_range!==0}" @click="hotdeal_range=0">
                        <small class="fs-px-12">I chose to use 'Hot Deal'</small>
                        and PAY $3
                    </div>
                    <div class="py-3 border-0 rounded-1 w-100 flex-center-center w-50 h-px-60 btn" :class="{'fw-bold bg-yellow-1':hotdeal_range===1,'bg-yellow-4':hotdeal_range!==1}" @click="hotdeal_range=1">
                        Maybe next time
                    </div>
                </div>
                <p class="small text-burgundy-4 pb-4">* Using paid service 'hot deal' will be on 'Hot Deal Section' which will have better access than ordinary open deals.<br>
* Please be noted that ‘Hot Deal’ is not refundable. Refund will not be made even if an uploaded deal is canceled by seller or expired. </p>
            </div>
            <div class="flex-between-center py-3">
                <button type="submit" class="py-3 border-0 btn rounded-1 bg-yellow-1 w-100 flex-center-center fw-bold" @click="upload">
                    Upload Deal
                </button>
            </div>
        </div>




        <!-- upload ticket summary -->
        
        <b-modal v-model="summaryPPisShown" centered @hidden="$emit('close')" no-close-on-backdrop scrollable  body-class="overflow-auto" content-class="max-h-vh-90" header-class="border-bottom-0">
            
            <template #modal-header>
                <h6 class="text-center w-100 py-2">UPLOAD TICKET SUMMARY</h6>
            </template>

            <div>
                <div>
                    <small class="fs-px-13 text-muted">Deal Title</small>
                    <p class="fs-px-13 mt-2">
                        <b class="fw-bold">{{title}}</b>
                    </p>
                </div>
                <hr>
                <div class="fs-px-13">
                    <small class="text-muted">SELLER’S COMMENT</small>
                    <p class="mt-2">
                        <b class="fw-bold">{{comment}}</b>
                    </p>
                </div>
                <hr>
                <div class="fs-px-13">
                    <small class="text-muted">Deal's ticket Information</small>
                    <div class="flex-between-center mt-2">
                        <span>Price per ticket</span>
                        <span class="fw-bold">{{sell_price == null ? 0 : sell_price.toLocaleString()}} {{sell_coin}}</span>
                    </div>
                    <div class="flex-between-center mt-2">
                        <span>Total ticket quantity</span>
                        <span class="fw-bold">{{total_ticket == null ? 0 : total_ticket.toLocaleString()}}</span>
                    </div>
                    <div class="flex-between-center mt-2">
                        <span>Total Amount</span>
                        <span class="fw-bold"><span class="text-danger">{{total_sell_coin}} {{sell_coin}}</span> (=$ {{ total_sell_coin_usdt }})</span>
                    </div>
                </div>
                <hr>
                <div class="fs-px-13 flex-between-center mt-2">
                    <span>Deal's Valid time</span>
                    <span class="fw-bold" v-if="deal_range===0">72 HOURS</span>
                    <span class="fw-bold" v-if="deal_range===1">1 WEEK</span>
                </div>
                <hr>
                <div class="fs-px-13">
                    <div class="flex-between-center mt-2">
                        <span>Hot Deal Service</span>
                        <span class="fw-bold" v-if="hotdeal_range===0">YES</span>
                        <span class="fw-bold" v-if="hotdeal_range===1">NO</span>
                    </div>
                    <button class="py-2 fs-px-13 mt-2 fw-500 btn btn-transparent bg-yellow-1 w-100" v-if="hotdeal_range ==0 &&  able_ticket==false" @click="TicketPayment()">Choose Payment Currency</button>
                    <div class="flex-between-center mt-2 "  v-if="hotdeal_range ==0 &&  able_ticket==true" >
                        <span>Hot Deal Payment</span>
                        <span class="fw-bold">{{hotdeal_price}} {{ hotdeal_coin}}</span>
                    </div>
                </div>
                <hr>
                <div class="fs-px-13">
                    <div>
                        <div class="flex-between-center mt-2">
                            <span>Estimated Profit %</span>
                            <span class="fw-bold">{{profit}} %</span>
                        </div>
                        <small class="fs-px-11 text-gray-500">*(Based on rate retrieved from Binance at {{ last_time }})</small>
                    </div>
                    <div>
                        <div class="flex-between-center mt-2">
                            <span>Transaction Fee ({{success_fee}}%)</span>
                            <span class="fw-bold">{{CheckBalance(sell_coin,success_fee_price)}} {{sell_coin}}</span>
                        </div>
                        <small class="fs-px-11 text-gray-500">*(Service fee will be automatically charged and deducted from all successful sell deals’ earnings)</small>
                    </div>
                </div>
            </div>
            
            <template #modal-footer>
                <div class="d-flex w-100">
                <button class="py-3 fs-px-12 btn btn-transparent w-50" @click="summaryPPisShown = false">Go Back</button>
                <button class="py-2 fs-px-12 fw-bold btn btn-transparent bg-yellow-1 w-50" @click="ConfirmDeal()">Confirm Upload</button>
                </div>
            </template>
        </b-modal>

        <b-modal v-model="paymentPPisShown" hide-header-close centered @hidden="$emit('close')" no-close-on-backdrop body-class="overflow-auto" content-class="max-h-vh-50" header-class="border-bottom-0">
            <p class="text-center fs-px-14 mb-3">Select currency you wish to make payment with</p>
            <ul class="payment_token flex-around-center text-center gap-2 flex-wrap">
                <li class="" :class="{'select':ticketTokenSelect==index}" v-for="(item,index) in sell_token" :key="index" @click="ClickTicket(item,index)">
                    <img :src="require(`@/assets/img/icon/color/${item.token_name}.svg`)" :alt="item.token_name" class="img-fluid w-100 mb-3 border-yellow-1">
                    <span class="uppercase" :class="{'text-gray-500':ticketTokenSelect!==index, 'text-dark fw-bold':ticketTokenSelect===index}">{{item.token_name}}</span>
                </li>
            </ul>
            <div class="flex-between-center mt-2">
                <span>Payment Amount</span>
                <span class="fw-bold">{{CheckShowHotdeal(ticket_amount,ticket_coin)}} {{ticket_coin}} (=${{ticket_usdt_price}})</span>
            </div>
            <small class="fs-px-11 text-gray-500">(Based on rate retrieved from Binance at {{last_time}})</small>
            
            <div class="flex-between-center mt-2">
                <span>My Balance</span>
                <span class="fw-bold">{{CheckBalance(ticket_coin,ticket_my_balance)}} {{ticket_coin}}</span>
            </div>
            
            <template #modal-footer>
                <div class="d-flex w-100">
                <button class="py-3 fs-px-12 btn btn-transparent w-50" @click="TicketPaymentBack()">Go Back</button>
                <button class="py-2 fs-px-12 fw-bold btn btn-transparent bg-yellow-1 w-50" @click="ConfirmPayment()" >Confirm Currency</button>
                </div>
            </template>
        </b-modal>

        <b-modal v-model="checkPPisShown" hide-header-close centered @hidden="$emit('close')" no-close-on-backdrop body-class="overflow-auto" content-class="max-h-vh-50" header-class="border-bottom-0">
            <h3 class="text-center fw-bold fs-px-14 mb-3">Enter your password to confirm upload</h3>
            <div class="h-px-48 flex-between-center border-bottom mb-2">
                <input :type="pw_show? 'text' : 'password'" class="form-control border-0 h-100 fs-px-12" placeholder="Enter your password" v-model="password" >
                <div class="px-2">
                    <img src="@/assets/img/layout_icon/show.svg" alt="show password" width="30" v-show="!pw_show" @click="pw_show = true">
                    <img src="@/assets/img/layout_icon/hide.svg" alt="hide password" width="30" v-show="pw_show" @click="pw_show = false">
                </div>
            </div>
            <router-link to="/reset-pw" class="text-danger p-2">
                    Forgot password?
            </router-link>
            <template #modal-footer>
                <div class="d-flex w-100">
                <button class="py-3 fs-px-12 btn btn-transparent w-50" @click="summaryPPisShown = true, checkPPisShown = false">Go Back</button>
                <button class="py-2 fs-px-12 fw-bold btn btn-transparent bg-yellow-1 w-50" @click="DealConfirm()">Confirm</button>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
import { BModal } from 'bootstrap-vue'
var validator = require('validator');
import SubHeader from '@/components/common/SubHeader.vue'
const CryptoJS = require("crypto-js");
const exactMath = require('exact-math');
export default {
    components:{
        SubHeader,BModal
    },
    data(){
        return{
            title: '',
            title_error:false,
            comment: '',
            comment_error : false,
            prizeTokenSelect:0,
            prize_token:[
                {
                    token_name:'btc',
                    symbol :'BTC'
                },
                {
                    token_name:'eth',
                    symbol :'ETH'
                },
                {
                    token_name:'bnb',
                    symbol :'BNB'
                },
                {
                    token_name:'trx',
                    symbol :'TRX'
                },
                {
                    token_name:'usdt',
                    symbol :'USDT'
                },
                {
                    token_name:'ocb',
                    symbol :'OCB'
                }
            ],
            
            prizeTokenQuantity:null,
            token_quantity_desc:false,
            token_sell_desc : false,

            profit:'',
            profit_desc:false,
            ticketTokenSelect :0,
            ticket_coin : 'BTC',
            ticket_usdt_price:'',
            ticket_my_balance :'',
            sellTokenSelect:0,
            sellTokenQuantity:null,
            sell_token:[
            {
                    token_name:'btc',
                    symbol :'BTC'
                },
                {
                    token_name:'eth',
                    symbol :'ETH'
                },
                {
                    token_name:'bnb',
                    symbol :'BNB'
                },
                {
                    token_name:'trx',
                    symbol :'TRX'
                },
                {
                    token_name:'usdt',
                    symbol :'USDT'
                },
                {
                    token_name:'ocb',
                    symbol :'OCB'
                }
            ],
            sell_token_desc:false,
            total_ticket:'',

            deal_range:0,
            hotdeal_range:0,
            hotdeal_desc:false,
            hotdeal_coin:'',
            hotdeal_price : '',
            summaryPPisShown: false,
            paymentPPisShown: false,
            checkPPisShown: false,

            password:'',
            pw_show: false,
            trading_coin : 'BTC',
            trading_price : '',
            trading_usdt_price:'',
            trading_my_price : '',
            my_wallet : [],
            rate : [],
            last_time : '',
            sell_coin : 'BTC',
            sell_coin_t_min : '',
            sell_rate : '',
            sell_usdt_price : '',
            sell_amount :'',
            sell_price : '',
            trading_total_usdt_price :'',
            cancel_fee : '',
            total_sell_coin : '',
            total_sell_coin_usdt : '',
            ticket_amount :'',
            success_fee : '',
            success_fee_price : '',
            able_ticket : false,

        }
    },
    mounted(){
        
        this.GetWalletList();

    },
    methods:{
        CheckShowHotdeal(price,coin){
            if(coin =='ETH'){
                const a = exactMath.floor(price,-4);

                console.log(a);

                // const b = exactMath.

                return a;
            }else{
                return price
            }

        },
        CheckWidthStyle(type){
            
            if(type=='trading_price'){
                const trading_price  = this.trading_price ;
                if(trading_price===null){
                    return 'width:1ch'
                }else if(trading_price==false){
                    return 'width:1ch'
                }else{
                    return `width:${this.trading_price.length}ch`;
                }
                
                // width: (trading_price===null||trading_price==false?1:trading_price.length) +'ch' 
            }else if(type=='profit'){
                const profit  = this.profit ;
                if(profit===null){
                    return 'width:1ch'
                }else if(profit==false){
                    return 'width:1ch'
                }else{
                    return `width:${this.profit.length}ch`;
                }
                
                // width: (trading_price===null||trading_price==false?1:trading_price.length) +'ch' 
            }else if(type=='sell_price'){
                const sell_price  = this.sell_price ;
                if(sell_price===null){
                    return 'width:1ch'
                }else if(sell_price==false){
                    return 'width:1ch'
                }else{
                    return `width:${this.sell_price.length}ch`;
                }
                
                // width: (trading_price===null||trading_price==false?1:trading_price.length) +'ch' 
            }
            
        },
        CheckSellTicket(){
            const total_ticket = this.total_ticket;
            const sell_coin = this.sell_coin;

            let rate = '';
            let sell_up = '';
            if(total_ticket !=''){
                for (const el of this.rate) {
                    if(sell_coin ==el.symbol){
                        rate = el.rate;
                        sell_up = el.t_up;
                    }
                    
                }
                
                if(!isNaN(total_ticket)){

                    const total_ticket_check = total_ticket*1;

                    if(total_ticket_check > 0){
                        
                        const t_balance = exactMath.div(this.sell_amount,total_ticket);
                        const sell_up_a = exactMath.mul(sell_up,-1);

                        if(sell_up_a !=0){
                            const sell_price = exactMath.ceil(t_balance,sell_up_a) ;
                            this.sell_price = sell_price+"";
                            const usdt = exactMath.mul(sell_price,rate);
                            this.sell_usdt_price = usdt;
                        }else{
                            const sell_price = Math.ceil(t_balance) ;
                            this.sell_price = sell_price;
                            const usdt = exactMath.mul(sell_price,rate);
                            this.sell_usdt_price = usdt;
                        }
                        
                        
                        // this.CheckSellTotalTicket()
                    }else{
                        this.sell_price = '';
                    }
                    
                }else{
                    this.sell_usdt_price = ''
                    this.sell_price = '';
                }
                
            }else{
                this.sell_usdt_price = ''
                this.sell_price = '';
            }
        },
        ConfirmDeal(){
            this.password ='';
            
            if(this.hotdeal_range ==0){
                const able_ticket = this.able_ticket;

                if(able_ticket){
                    this.summaryPPisShown = false;
                    this.checkPPisShown= true;
                }else{
                    this.$pp({
                        msg: "Payment for 'Hot Deal' not made", 
                        is_confirm: false, 
                        auto_hide:true
                    })
                }
            }else{
                this.summaryPPisShown = false;
                this.checkPPisShown= true;
            }
            
        },
        DealConfirm(){
            const password = this.password;
            const trading_coin = this.trading_coin;
            const trading_price = this.trading_price;
            const sell_price = this.sell_price;
            const total_ticket = this.total_ticket;
            const sell_coin = this.sell_coin;
            const title = this.title;
            const comment = this.comment;

            const deal_range = this.deal_range;
            const hotdeal_range = this.hotdeal_range;
            const hotdeal_price = this.hotdeal_price;
            const hotdeal_coin = this.hotdeal_coin;

            
            const body = {title,comment,trading_coin,trading_price,sell_price,total_ticket,sell_coin,deal_range,hotdeal_range,password,hotdeal_coin,hotdeal_price};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

            this.$http.post('/member/ticket/DealConfirm',{req}).then(
                (res) =>  {
                    if(res.status == 200){
                        this.loading = false;
                        if(res.data.code =="200"){
                            
                            this.summaryPPisShown = false
                            this.checkPPisShown = false
                            this.$pp({
                                msg: 'Success Deal', 
                                is_confirm: false, 
                                auto_hide:true
                            })
                            this.$router.push({path:'/mydeal/sell/sale'});
                        }else if(res.data.code =="100"){
                            this.$pp({
                                msg: 'Check Amount', 
                                is_confirm: false, 
                                auto_hide:true
                            })
                        }else if(res.data.code =="300"){
                            this.$pp({
                                msg: 'Wrong password. Please check your password.', 
                                is_confirm: false, 
                                auto_hide:true
                            })
                        }else if(res.data.code =="500"){
                            this.$pp({
                                msg: 'Check HotDeal Ticket', 
                                is_confirm: false, 
                                auto_hide:true
                            })
                        }else if(res.data.code =="9999"){
                            this.$store.dispatch('logout').then(
                                ()=>{
                                location.href = `/signin`;
                                }
                            );
                        }
                    }
                }   
            )
        },
        ConfirmPayment(){
            const ticket_amount = this.ticket_amount;
            const ticket_coin = this.ticket_coin;

            this.hotdeal_coin = ticket_coin;
            this.hotdeal_price = ticket_amount;
            const body = {ticket_amount,ticket_coin};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

            this.$http.post('/member/ticket/BuyPaymentTicketCheck',{req}).then(
                (res) =>  {
                    if(res.status == 200){
                        this.loading = false;
                        if(res.data.code =="200"){
                            this.able_ticket = true;
                            // this.$pp({
                            //     msg: 'Payment made successfully!', 
                            //     is_confirm: false, 
                            //     auto_hide:true
                            // })
                            this.TicketPaymentBack();
                        }else if(res.data.code =="100"){
                            this.able_ticket = false;
                            this.$pp({
                                msg: 'Check Amount', 
                                is_confirm: false, 
                                auto_hide:true
                            })
                        }else if(res.data.code =="9999"){
                            this.$store.dispatch('logout').then(
                                ()=>{
                                location.href = `/signin`;
                                }
                            );
                        }
                    }
                }   
            )

        },
        CheckTitle(){
            if(this.title.length>=20){
                this.title_error=true;
                this.title = this.title.substring(0,20);
            }else{
                this.title_error=false;
            }
        },
        TicketPaymentBack(){
            this.summaryPPisShown = true;
            this.paymentPPisShown = false;
        },
        CheckProfit(){
            const profit = this.profit;
            const sell_coin = this.sell_coin;
            const trading_coin = this.trading_coin;
            const trading_price = this.trading_price;

            const check_float = validator.isFloat(profit);

            if(profit.length > 1){
                if(trading_price.charAt(0) =='0'){
                    if(trading_price.charAt(1) !='.'){
                        this.profit = profit.charAt(1);
                        this.CheckProfit();
                        return false;
                    }
                }
            }

            if(profit.charAt(0) =='-'){
                this.profit = '';
                this.sell_price = '';
                this.sell_amount ='';
                this.total_ticket ='';
                this.trading_total_usdt_price = '';
                this.CheckProfit();
                return false;
            }


            if(trading_price !=''){
                if(profit !=''){
                    
                    if(check_float){
                        let last_char = profit.charAt(profit.length-1);
                        if(last_char !='.'){

                            let trading_rate = '';
                            for (const el of this.rate) {
                                if(trading_coin ==el.symbol){
                                    trading_rate = el.rate;
                                }
                            }

                            const trading_total_price = exactMath.mul(trading_price,trading_rate);

                            let sell_per = exactMath.mul(profit,0.01);
                            sell_per = exactMath.add(sell_per,1);
                            const sell_total_price = exactMath.mul(trading_total_price,sell_per);
                            
                            let sell_rate = '';
                            let sell_up = '';
                            for (const el of this.rate) {
                                if(sell_coin ==el.symbol){
                                    sell_rate = el.rate;
                                    sell_up = el.t_up;
                                }
                            }

                            const sell_up_a = exactMath.mul(sell_up,-1);

                            
                            if(sell_up_a !=0){
                                const sell_amount = exactMath.div(sell_total_price,sell_rate);
                                const sell_amount_ceil = exactMath.ceil(sell_amount,sell_up_a);


                                this.sell_amount = sell_amount_ceil;
                                const trading_total_usdt_price = exactMath.mul(sell_rate,sell_amount);
                                this.trading_total_usdt_price =  exactMath.ceil(trading_total_usdt_price,-5);
                            }else{
                                const sell_amount = exactMath.div(sell_total_price,sell_rate);
                                const sell_amount_ceil = Math.ceil(sell_amount);


                                this.sell_amount = sell_amount_ceil;
                                const trading_total_usdt_price = exactMath.mul(sell_rate,sell_amount);
                                this.trading_total_usdt_price = exactMath.ceil(trading_total_usdt_price,-5);
                            }
                        }else{
                            this.sell_amount = '';
                            this.sell_price = '';
                            this.trading_total_usdt_price = '';    
                        }
                    }else{
                        this.profit = '';
                        this.sell_price = '';
                        this.sell_amount ='';
                        this.total_ticket ='';
                        this.trading_total_usdt_price = '';
                    }
                }else{
                    this.sell_amount = '';
                    this.trading_total_usdt_price = '';    
                }
            }else{
                this.sell_amount = '';
                this.trading_total_usdt_price = '';
            }
            
            this.sell_price = '';
            this.total_ticket ='';
            
        },
        CheckTradingPrice(){
            // if(this.trading_price.match(/[^0-9]/g)){
            //     this.trading_price = this.trading_price.replace(/[^0-9]/g, '');
            // }
            let trading_price = this.trading_price
            const trading_coin = this.trading_coin;
            let rate = '';
            let max_s = '';
            const check_float = validator.isFloat(trading_price);

            if(trading_price.length > 1){
                if(trading_price.charAt(0) =='0'){
                    if(trading_price.charAt(1) !='.'){
                        this.trading_price = trading_price.substring(1,trading_price.length);
                        this.CheckWidthStyle('trading_price');
                        this.CheckTradingPrice();
                        return false;
                    }
                }
            }
            
            if(trading_price.charAt(0) =='-'){
                this.trading_price = '';
                this.sell_price = '';
                this.total_ticket ='';
                this.CheckProfit();
                return false;
            }

            if(trading_price.charAt(0) =='.'){
                this.trading_price = '';
                this.CheckTradingPrice();
                return false;
            }

            if(check_float){
                let last_char = trading_price.charAt(trading_price.length-1);
                if(last_char !='.'){

                    for (const el of this.rate){
                        
                        if(trading_coin ==el.symbol){
                            rate = el.rate;
                            max_s = el.t_up;
                        }
                    }

                    const float_arr = this.trading_price.split('.');

                    if(float_arr.length > 1){
                        if(float_arr[1].length > max_s){
                            let f_string = float_arr[1]+ '';
                            trading_price = float_arr[0] +'.'+f_string.substring(0, max_s);
                            this.trading_price = trading_price;
                            this.CheckTradingPrice();
                            this.CheckWidthStyle('trading_price');
                            return false;
                        }
                    }

                    

                    
                    
                    let balance = 0;
                    for (const el of this.my_wallet) {
                        if(trading_coin ==el.symbol){
                            balance = el.balance;
                        }
                    }

                    const c_bal = exactMath.sub(balance,trading_price);

                    if(c_bal < 0){
                        this.trading_price = balance +'';
                        trading_price = balance +''
                        this.CheckTradingPrice();
                        this.CheckWidthStyle('trading_price');

                        this.$pp({
                            msg: 'Insufficient Balance',
                            auto_hide:true
                        })

                        return false;

                    }
                    const usdt = exactMath.mul(trading_price,rate);
                    this.trading_usdt_price = usdt;
                
                    this.sell_price = '';
                    this.total_ticket ='';
                    this.CheckProfit();
                }
            }else{
                this.trading_price = '';
            }
        },
        CheckBalance(coin,balance){
            let w_up = '';
            for (const el of this.my_wallet) {
                if(coin ==el.symbol){
                    w_up = el.w_up;
                }
            }
            const balance_t = balance+'';
            if(balance_t > 0 ){
                const float_arr = balance_t.split('.');

                if(float_arr.length ==2){
                    let f_string = float_arr[1]+ '';
                    const price = float_arr[0] +'.'+f_string.substring(0, w_up);
                    return price;
                }else{
                    return balance_t;
                }
                
            }else{
                return balance_t;
            }
        },
        CheckSellPrice(){
            let sell_price = this.sell_price;
            const sell_coin = this.sell_coin;

            let rate = '';
            let max_s = '';
            const check_float = validator.isFloat(sell_price);

            if(sell_price.length > 1){
                if(sell_price.charAt(0) =='0'){
                    if(sell_price.charAt(1) !='.'){
                        this.sell_price = sell_price.charAt(1);
                        this.CheckSellPrice();
                        return false;
                    }
                }
            }
            
            if(sell_price.charAt(0) =='-'){
                this.sell_price = '';
                this.sell_usdt_price = ''
                this.total_ticket = '';
                this.CheckSellPrice();
                return false;
            }

            if(sell_price !=''){
                
                if(check_float){
                    for (const el of this.rate){
                        
                        if(sell_coin ==el.symbol){
                            rate = el.rate;
                            max_s = el.t_up;
                        }
                    }

                    const float_arr = this.sell_price.split('.');

                    if(float_arr.length > 1){
                        if(float_arr[1].length > max_s){
                            let f_string = float_arr[1]+ '';
                            console.log(float_arr);
                            sell_price = float_arr[0] +'.'+f_string.substring(0, max_s);
                            this.sell_price = sell_price;
                            this.CheckSellPrice();
                            this.CheckWidthStyle('sell_price');
                            return false;
                        }
                    }
                    const sell_price_check = sell_price*1;

                    if(sell_price_check > 0){
                        const usdt = exactMath.mul(sell_price,rate);
                        this.sell_usdt_price = usdt;
                        this.CheckSellPriceTicket()
                    }else{
                        this.total_ticket = '';
                    }
                    
                }else{
                    this.sell_price = '';
                    this.sell_usdt_price = ''
                    this.total_ticket = '';
                }
                
            }else{
                this.sell_usdt_price = ''
                this.total_ticket = '';
            }
            
            
        },
        CheckSellPriceTicket(){
            const trading_total_usdt_price = this.trading_total_usdt_price;
            const sell_coin = this.sell_coin;
            const sell_price = this.sell_price;
            
            let sell_rate = '';

            for (const el of this.rate) {
                if(sell_coin ==el.symbol){
                    sell_rate = el.rate;
                }
                
            }
            
            if(sell_price !=''){
                const sell_usdt = exactMath.mul(sell_price,sell_rate);

                const sell_total_ticket =exactMath.div(trading_total_usdt_price,sell_usdt)

                const total_ticket = Math.ceil(sell_total_ticket);
                if(!isNaN(total_ticket)){
                    this.total_ticket = total_ticket;
                }
                
                if(this.total_ticket < 9){
                    this.$pp({
                        msg: 'Price per ticket is too high, does not meet required minimum number of tickets. ', 
                        is_confirm: false, 
                        auto_hide:true
                    })
                    this.total_ticket = '';
                    this.sell_price = '';
                    this.CheckSellPrice();

                }
            }
            

        },
        CheckSellMin(){
            const sell_coin = this.sell_coin;
            let sell_coin_t_min = '';
            for (const el of this.rate) {
                if(sell_coin ==el.symbol){
                    sell_coin_t_min = el.t_min;
                }
                
            }

            this.sell_coin_t_min = sell_coin_t_min;
        },
        // CheckPaymentTicket(){
        //     const body = {};
        //     const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

        //     this.$http.post('/member/ticket/CheckPaymentTicket',{req}).then(
        //         (res) =>  {
        //             if(res.status == 200){
        //                 this.loading = false;
        //                 if(res.data.code =="200"){
        //                     this.able_ticket =true;
        //                 }else if(res.data.code =="100"){
        //                     this.able_ticket =false;
        //                 }else if(res.data.code =="9999"){
        //                     this.$store.dispatch('logout').then(
        //                         ()=>{
        //                         location.href = `/signin`;
        //                         }
        //                     );
        //                 }
        //             }
        //         }   
        //     )
        // },
        GetWalletList(){
            const body = {};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

            this.$http.post('/member/ticket/GetWalletList',{req}).then(
                (res) =>  {
                    if(res.status == 200){
                        this.loading = false;
                        if(res.data.code =="200"){
                            const e_body = res.data.body;
                            const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                            const d_res = bytes.toString(CryptoJS.enc.Utf8);
                            const body = JSON.parse(d_res)
                            this.my_wallet = body.wallet_list;
                            this.rate = body.rate_list;
                            this.last_time = body.update_time;
                            this.cancel_fee = body.cancel_fee;
                            this.success_fee = body.success_fee;
                            this.CheckMyWallet();
                            this.CheckSellMin();
                            
                        }else if(res.data.code =="9999"){
                            this.$store.dispatch('logout').then(
                                ()=>{
                                location.href = `/signin`;
                                }
                            );
                        }
                    }
                }   
            )
        },
        Submit(){
            event.preventDefault();
            this.able_ticket = false;
            this.summaryPPisShown = true;
        },
        CheckMyWallet(){
            const symbol = this.trading_coin;

            let balance = 0;
            for (const el of this.my_wallet) {
                if(symbol ==el.symbol){
                    balance = el.balance;
                }
            }

            this.trading_my_price = balance;
        },
        ClickTradingCoin(item,index){
            this.trading_coin = item.symbol;
            this.trading_price ='';
            this.profit ='';
            this.sell_price ='';
            this.total_ticket ='';
            this.sell_amount ='';
            this.trading_total_usdt_price = '';
            this.CheckMyWallet();

            this.prizeTokenSelect=index

        },
        TicketPayment(){
            this.ticket_coin = 'BTC';
            this.summaryPPisShown = false;
            this.paymentPPisShown= true;
            this.ticketTokenSelect = 0;
            let balance = 0;
            for (const el of this.my_wallet) {
                if(this.ticket_coin ==el.symbol){

                    
                    balance = el.balance;
                }
            }

            this.ticket_my_balance = balance;
            this.TicketInfo();
            
        },
        ClickTicket(item,index){
            this.ticket_coin = item.symbol;
            this.ticketTokenSelect = index;
            this.TicketInfo();
            let balance = 0;
            for (const el of this.my_wallet) {
                if(this.ticket_coin ==el.symbol){
                    balance = el.balance;
                }
            }

            this.ticket_my_balance = balance;
        },
        TicketInfo(){
            const ticket_coin = this.ticket_coin

            let rate = '';
            let t_up = '';
            
            for (const el of this.rate) {
                if(el.symbol ==ticket_coin){
                    rate = el.rate;
                    t_up = el.t_up;
                }
            }
            const t_up_a = exactMath.mul(t_up,-1);

            if(t_up_a !=0){
                const usdt = exactMath.div(1,rate);
                const ticket_amount = exactMath.mul(usdt,3);
                const ticket_amount_ceil = exactMath.ceil(ticket_amount,t_up_a);


                this.ticket_amount = ticket_amount_ceil;

                const ticket_usdt_price = exactMath.mul(ticket_amount_ceil,rate);
                this.ticket_usdt_price = exactMath.ceil(ticket_usdt_price,-2);
            }else{
                const usdt = exactMath.div(1,rate);
                const ticket_amount = exactMath.mul(usdt,3);
                const ticket_amount_ceil = Math.ceil(ticket_amount);


                this.ticket_amount = ticket_amount_ceil;

                const ticket_usdt_price = exactMath.mul(ticket_amount_ceil,rate);
                this.ticket_usdt_price = exactMath.ceil(ticket_usdt_price,-2);
            }

            
            

            
            
        },
        ClickSellCoin(item,index){
            this.sell_coin = item.symbol;
            this.sell_price = '';
            this.sell_limit = '';
            this.total_ticket = '';
            this.CheckSellMin();
            this.sellTokenSelect=index
            this.CheckProfit();

        },
        SellTotalInfo(){
            const sell_coin = this.sell_coin;
            const sell_price = this.sell_price;
            const total_ticket = this.total_ticket;
            const total_sell_coin = exactMath.mul(sell_price,total_ticket)
            this.total_sell_coin = total_sell_coin;

            let sell_rate = '';
            
            for (const el of this.rate) {
                if(sell_coin ==el.symbol){
                    sell_rate = el.rate;
                }
            }
            
            const total_sell_coin_usdt = exactMath.mul(sell_rate,total_sell_coin);
            this.total_sell_coin_usdt = exactMath.ceil(total_sell_coin_usdt,-5);

            this.success_fee_price = exactMath.mul(total_sell_coin,this.success_fee,0.01);
            if(this.hotdeal_range ==0){
                this.able_ticket = false;
                // this.CheckPaymentTicket();
            }else{
                this.able_ticket = false;
            }
            
        },
        upload(){
            if(this.title.length<1||this.title===''){
                this.$pp({
                    msg:`Enter deal's title. The first step to a good deal!`,
                    is_confirm: false, 
                    auto_hide:true
                })
                return false;
            }
            // else if(this.comment.length<1||this.comment===''){
            //     this.$pp({
            //         msg:`Enter deal's comment! Pitch your deal!`,
            //         is_confirm: false, 
            //         auto_hide:true
            //     })
            //     return false;
            // }

            const trading_price = this.trading_price;
            const sell_price = this.sell_price;

            const total_ticket = this.total_ticket;

            if(trading_price ==''){
                this.$pp({
                    msg:`Enter token quantity`,
                    is_confirm: false, 
                    auto_hide:true
                })
                return false;
            }

            if(sell_price ==''){
                this.$pp({
                    msg:`Enter Price per 1 Ticket`,
                    is_confirm: false, 
                    auto_hide:true
                })
                return false;
            }

            

            if(total_ticket ==''){
                this.$pp({
                    msg:`Enter Total Ticket Quantity`,
                    is_confirm: false, 
                    auto_hide:true
                })
                return false;
            }

            if(total_ticket <9){
                this.$pp({
                    msg:`Price per ticket is too high, does not meet required minimum number of tickets.`,
                    is_confirm: false, 
                    auto_hide:true
                })
                return false;
            }

            if(total_ticket >99999){
                this.$pp({
                    msg:`Price per ticket is too high, does not meet required minimum number of tickets.`,
                    is_confirm: false, 
                    auto_hide:true
                })
                return false;
            }

            const sell_coin_t_min = this.sell_coin_t_min;

            if(sell_price >=sell_coin_t_min){
                this.SellTotalInfo();
                this.summaryPPisShown = true;    
            }else{
                this.$pp({
                    msg:`The price per ticket is too low to meet the required minimum.`,
                    is_confirm: false, 
                    auto_hide:true
                })
            }
            
        }
    },

}
</script>
<style lang="scss" scoped>
.prize_token>li{
    min-width: 104px;
    width: 30%;
}
.prize_token img{
    margin-left: -3px;
    height: 35px;
    flex-shrink: 0;
    flex-grow: 0;
    width: 35px !important;
}
.payment_token{
    padding: 1em 0;
    >li{
        display: flex;
        flex-direction: column;
    }
    img{
        width: 45px !important;
        height: 45px;
    }
    .select{
    img{
        border: 3px solid yellow;
        border-radius: 50%;
        transform: scale(1.2);
        padding: 4px;
    }
}
}

::placeholder{
    -webkit-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  user-select:none;
}
</style>