<template>
    <div class="deal">
        <SubHeader :title="'DEALS'" :link="'/'" :profile="true" :wish="true" />
        <DealCate :idx="0" @ChangeSort="ChangeSort"/>
        <List :list="list" :show="true"></List>
        <Footer></Footer>
    </div>
</template>

<script>
// @ is an alias to /src
import List from '@/components/deal/List.vue'
import DealCate from '@/components/deal/CategoryBtns.vue'
import Footer from '@/components/common/Footer.vue'
import SubHeader from '@/components/common/SubHeader.vue'
const CryptoJS = require("crypto-js");

export default {
  name: 'Home',
  components: {
    List, Footer, SubHeader, DealCate
  },
  
  data(){
    return{
      list:[],
      sort : 'N',
      reward : '',
      buy : '',
    }
  },
  mounted(){
    this.GetDealList();
  },
  methods:{
    ChangeSort(item){
      this.reward = item.reward;
      this.buy = item.buy;
      this.sort = item.sort;
      this.GetDealList()
    },
    GetDealList(){
      const reward = this.reward;
      const buy = this.buy;
      const sort = this.sort;
      const body = {reward,buy,sort};
      const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

      this.$http.post('/member/deal/GetHotDealList',{req}).then(
        (res) =>  {
            if(res.status == 200){
                this.loading = false;
                if(res.data.code =="200"){
                    const e_body = res.data.body;
                    const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                    const d_res = bytes.toString(CryptoJS.enc.Utf8);
                    const body = JSON.parse(d_res)
                    this.list = body.list;
                }else if(res.data.code =="9999"){
                    this.$store.dispatch('logout').then(
                        ()=>{
                        location.href = `/signin`;
                        }
                    );
                }
            }
        }   
      )
    }
  }
}
</script>